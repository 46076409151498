import React from "react"
import SingleLayout from '../components/layouts/SingleLayout_10'
import Seo from "../components/seo"

import { FaLinkedin, FaFacebookSquare, 
  FaYoutube, FaTwitterSquare, FaEnvelope } from 'react-icons/fa';

const About = () => {
  const hero = {
    title:"Author Portfolio", 
    image: "/images/portfolio.jpg"
  }
  return (
    <>
      <SingleLayout hero={hero}>
        <Seo 
          title="Author Portfolio" 
          description="Explore what kind of programming and SEO posts are there. Read about our Coding Interview Guide" 
          image="/images/portfolio.jpg"
          />
        <div className="row">
		<div className="col-12 m-4 p-4">
			<h1>Portfolio of Author</h1>
<p>This page describes the work experience of Gorav Singal. Since the year I started working, job changes and startup experiences.</p>

<h2>About the Author</h2>
<p></p>

<div className="card m-4">
	<div className="media">
		<img className="m-2" src="/images/gorav.singal.jpg" alt="Gorav Singal" />
		<div className="media-body align-self-center">
		<h5 className="mt-0">Gorav Singal</h5>
			I'm a software engineer by profession. Currently, I'm working for Adobe Systems, India.
			Its been more than 10 years in Adobe. I'm working since 2007. And, I still code. 
			<br/><br/>
			My current designation is Computer Scientist. I just love this fancy designation name.
			<br/>
			Below are my social addresses:

			<section className="slice slice-lg">
				<div className="container">
					<div className="row">
						<div className="col">
							<a className="btn btn-default" href="https://www.linkedin.com/in/goravsingal/" target="_blank" rel="noreferrer">
								<FaLinkedin className="h3" style={{color: "#007bb6"}} />
							</a>
							<a className="btn btn-default" href="https://www.facebook.com/gorav.singal" target="_blank" rel="noreferrer">
								<FaFacebookSquare className="h3" style={{color:"#3b5998"}} />
							</a>
							<a className="btn btn-default" href="https://www.youtube.com/channel/UCNNxPxH_zIPxvWy5QMFkruA" target="_blank" rel="noreferrer">
								<FaYoutube className="h3" style={{color:"red"}} />
							</a>
							<a className="btn btn-default" href="https://twitter.com/goravsingal" target="_blank" rel="noreferrer">
                <FaTwitterSquare className="h3" style={{color:"#55acee"}} />
							</a>
							<a className="btn btn-default" href="mailto:gorav.singal@gmail.com" target="_blank" rel="noreferrer">
								<FaEnvelope className="h3" style={{color: "#007bb6"}} />
							</a>
						</div>
					</div>
			</div>
			</section>
		</div>
  	</div>
</div>


<p></p>

<h2>My Official Jobs</h2>
<p>I started working from NEC HCL (now NEC), a japanese company. I got two offers when I started my career. I left higher paying job just because I did not want to work on Java. I chose NEC as I would be working on C/C++, my first love. Although, currently I'm working on Java and left C/C++ since long.
</p>
<p></p>
<div className="ml-4">
	<h4>Adobe Systems</h4>
	<p>I joined Adobe, India in March 2010. There I worked on a variety of things starting from C++ to Action script. After around 2 years, I switched gears towards enterprise java implementations. I worked mostly on Java, web services, spring. I learned a lot here. I touched lot of technology stacks here.</p>

	<h4>NEC</h4>
	<p>I worked on a storage product in C++. I got some awesome development work. And I also got a chance to goto Poland. I worked there for around 2.5 years. I worked with some of talented mentors.</p>
</div>
<p></p>

<hr/>

<h2>My Un-Official Jobs</h2>
<p>These are my work that I started because of my interest in Startups. I hit the startup worm in late 2009. I started an educational venture which failed, but it left immense knowledge in me. Till now, I'm struggling to find a hit startup that can feed me in my retirement.</p>
<p>I do have a decent running idea, planndesign.com whose content is managed by my wife now.</p>
<p>I did never plan to work as freelancer. I always wanted to create products that can be helpful to people.</p>

<p></p>
<div className="ml-4">
	<h2>My Websites</h2>
	<p>These are the websites that I have longer plans with. I will run these for long.</p>
	<div className="row">
		<div className="col">
			<ul className="list-group list-group-flush">
				<li className="list-group-item"><a href="https://www.planndesign.com" target="_blank" rel="noreferrer">Plan'n'Design.com (A marketplace for Interior Designers, Alexa Rank = 1.4L)</a></li>
				<li className="list-group-item"><a href="https://www.gyanblog.com" target="_blank" rel="noreferrer">GyanBlog.com (A technical blog)</a></li>
				<li className="list-group-item"><a href="https://www.lifeintext.com" target="_blank" rel="noreferrer">LifeInText.com (A personal journal)</a></li>
				<li className="list-group-item"><a href="https://www.gyanbyte.com" target="_blank" rel="noreferrer">GyanByte.com (A dose of Gyan, not maintained though)</a></li>
				<li className="list-group-item"><a href="https://www.appsfactory.dev" target="_blank" rel="noreferrer">appsfactory.dev (Idea is to have lot of tools/apps)</a></li>
				<li className="list-group-item">
						KGsePG dot com (The name is sold to someone else. I raised this website to alexa ranking 4L worldwide.)
				</li>
			</ul>
		</div>
	</div>
</div>
<hr/>
<p></p>

<h2>My Work Brief</h2>

<div className="row">
	<div className="col-md-6">
		<div className="card border-dark mb-2">
			<div className="card-header">
			<h4>Intrusion Detection Monitor in Cloud (Year 2015)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">A monitor to detect unintentional change in Adobe binaries</h5>
				<p>It was developed for alerting and notifying for any unintentional binary change in public cloud. I deplpoyed this solution in aws in three different geographies, for each origin server.</p>
				<h5>Features</h5>
				<ul>
					<li>Communication across intra corp network and public internet.</li>
					<li>Provision to query for source of truth sitting in corp network</li>
					<li>Messaging based communication across</li>
					<li>Alerting based on SNS on Mobile app and Emails</li>
					<li>Caught several issues where Akamai purge happens before binaries reached origin servers</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
						<li>AWS EC2</li>
						<li>SQS, SNS, SES</li>
						<li>Java, JMS, AWS SDK</li>
						<li>Android app for notification/alerting</li>
						<li>Multi-geo deployments/workers</li>
						<li>Cloud Architecture</li>
						<li>Mysql RDS, Web service</li>
						<li>Splunk, Veracode, Findbugs</li>
				</ul>
			</div>
		</div>
	</div>
	<div className="col-md-6">
		<div className="card bg-light mb-2">
			<div className="card-header">
				<h4>Design of a Action and Analytic engine for reporting of Security issues (Year 2017)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">A cloud solution for reporting Security issues and actionable items</h5>
				<p>This solution was architected and developed as a cloud based solution to detect securty issues and create actionable items for product teams.</p>
				<h5>Features</h5>
				<ul>
					<li>A dynamic rules based engine which runs in true serverless architecture</li>
					<li>Ingestion service which feeds data to Elastic Search in AWS via lambda functions</li>
					<li>True micro-services in form of lambda functions which forms a chain of action and analytics</li>
					<li>Messaging based communication across corp network and aws public network</li>
					<li>Created Jira tickets for product teams</li>
					<li>Intelligently create Jira tickets for all possible combinations</li>
					<li>Automation to report Jir tickets for Penetration testing activities.</li>
					<li>Framework to include any security tool so that its ingestion and reporting will be done easily.</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Cloud architecture, Serverless architecture</li>
					<li>AWS Lambda functions, S3, Cloudwatch</li>
					<li>Elastic Search, MongoDB, Redis, Backbone JS</li>
					<li>Node.js, Docker, Splunk, Consul for Service discovery</li>
					<li>Jira automation via Rest api</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div className="row">
	<div className="col-md-6">
		<div className="card bg-light mb-2">
			<div className="card-header">
				<h4>Reporting of 3rd party library vulnerabilities - Security project (Year 2018)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">A tool to report 3rd party library vulnerabilities</h5>
				<p>One of top security issue that also exist in OWASP Top 10, "Using Components with Known Vulnerabilities". This tool solves this issue. This is a tool provides web service and a web UI, reports list of security vulnerabailities in user's project.</p>
				<h5>Features</h5>
				<ul>
					<li>Report vulnerbailities</li>
					<li>Plugins for users which they can embed in their build system automation</li>
					<li>Instant reporting of vulnerabilities</li>
					<li>Syncing security related from various sources like NVD, NPM, other 3rd party vendors</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Node.js, MongoDB, Elastic Search</li>
					<li>Docker, Micro services</li>
					<li>Facebook thrift for RPC among micro services</li>
					<li>Redis, RabbitMQ</li>
					<li>Splunk, Grafana</li>
				</ul>
			</div>
		</div>
	</div>
	<div className="col-md-6">
		<div className="card border-dark mb-2">
			<div className="card-header">
				<h4>Hubble - A compliance tool in python (Year 2020)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">An open source compliance tool - Hubble</h5>
				<p>This compliance tool automates the security standard that must exists on a host. There is an organization CIS which has defined a set of security standards for each kind of host system.</p>
				<h5>Features</h5>
				<ul>
					<li>Compliance score status</li>
					<li>Automation based on salt libraries</li>
					<li>Gather data from a host system and report it</li>
					<li>File integrity checks</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Python</li>
					<li>Salt modules, grains as library</li>
					<li>OSQuery</li>
					<li>Yaml files, Splunk</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div className="row">
	<div className="col-md-6">
		<div className="card border-dark mb-2">
			<div className="card-header">
				<h4>Automation pipeline to release or deploy distributable binaries/executables to CDN (Year 2015)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">Automation pipeline for deploying distributable bits to CDN</h5>
				<h5>Features</h5>
				<ul>
					<li>Standalone workers for pushing/updating/deleting binaries to origin servers</li>
					<li>Akamai Purge for new binaries pull</li>
					<li>Alert for an unwanted modificatio on file system</li>
					<li>Secure system for users to push/update/delete binaries</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Java</li>
					<li>Web service, Rest APIs</li>
					<li>Akamai CDN, Akamai APIs</li>
					<li>JBoss, Oracle, Hibernate</li>
				</ul>
			</div>
		</div>
	</div>
	<div className="col-md-6">
		<div className="card bg-light mb-2">
			<div className="card-header">
				<h4>Development of common libraries for the team (Year 2015)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">Collection of reusable libraries and components</h5>
				<h5>Libraries for</h5>
				<ul>
					<li>Managing secrets/credentials from various sources like Cyberark, Vault, Local file read</li>
					<li>Generic rest client with 2-way SSL configuration</li>
					<li>Core set of code for multiple usages, Email lib</li>
					<li>Collect various statistics like performance, memory</li>
					<li>Monitoring tools like remote stats and dumps</li>
					<li>JMS/SQS listeners and producers</li>
					<li>SSL certificate creation utilities</li>
					<li>File tranfer wrapper over ftp/sftp/ftps/scp</li>
					<li>Wrapper libraries over Spring/Spring security</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Design and development of libraries</li>
					<li>Java</li>
					<li>Spring, Java beans, Spring security</li>
					<li>Apache common libraries</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div className="row">
	<div className="col-md-6">
		<div className="card bg-light mb-2">
			<div className="card-header">
				<h4>Code Signing Automation (Year 2014)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">Secure pipeline for Code Signing apps/binaries</h5>
				<h5>Features</h5>
				<ul>
					<li>An excellent architecture for multiple type signing workers</li>
					<li>Support for multiple signing types like Windows/Mac/Java etc</li>
					<li>Certificates are placed in secure environment - HSM</li>
					<li>Secure role based access for teams</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Architected the standalone workers</li>
					<li>Java, Ruby, Splunk, Oracle</li>
					<li>Concurrent signing/file transers</li>
					<li>S3 chunk upload/download in parallel</li>
				</ul>
			</div>
		</div>
	</div>
	<div className="col-md-6">
		<div className="card border-dark mb-2">
			<div className="card-header">
				<h4>Build repository metadata, and workflow triggers</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">A repository of build metadata and trigger points for various workflows</h5>
				<h5>Features</h5>
				<ul>
					<li>Web service exposing more than 100 APIs</li>
					<li>Soap and Rest API support</li>
					<li>JMS messaging for various triggers</li>
					<li>Role based access</li>
					<li>Storing lot of metadata about builds for various products</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Java, JMS</li>
					<li>Spring, Java beans, Spring security</li>
					<li>Apache Active MQ, Apache commons</li>
					<li>JBoss, Oracle, Hibernate</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div className="row">
	<div className="col-md-6">
		<div className="card border-dark mb-2">
			<div className="card-header">
				<h4>Unified Security Backlog for teams</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">Creating unified security backlog for various security related workflows</h5>
				<h5>Features</h5>
				<ul>
					<li>Micro service based architecture</li>
					<li>Webservice over Jira to create any kind of issue in any queue</li>
					<li>Automation workflows for various types</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Java, Python</li>
					<li>Spring, Spring security</li>
					<li>Messaging, MongoDB</li>
					<li>JBoss, Oracle, Hibernate</li>
				</ul>
			</div>
		</div>
	</div>
	<div className="col-md-6">
		<div className="card bg-light mb-2">
			<div className="card-header">
				<h4>Ethical Hacking</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">Penetration testing and Ethical Hacking</h5>
				<h5>Overview</h5>
				<ul>
					<li>Took part in penetration testing activities for some projects</li>
					<li>Various security tools implementations</li>
					<li>Won live competition in Ethical hacking competition</li>
					<li>Using of securiity best practices in projects</li>
					<li>Used various tools in this domain like Burp</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div className="row">
	<div className="col-md-6">
		<div className="card bg-light mb-2">
			<div className="card-header">
				<h4>Planndesign.com (Year 2017)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">A web portal for Interior Designers and Architects</h5>
				<h5>Features</h5>
				<ul>
					<li>Upload of Autocad drawings</li>
					<li>User can buy subscription for premium drawings</li>
					<li>Paypal for international users and Instamojo for Indian users</li>
					<li>Various statistics across websites</li>
					<li>Youtube video sync from planndesign.com channel</li>
					<li>Blogging section</li>
					<li>Amazon Affiliate. Country detection and country based redirection</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
						<li>Php, Aws S3, Google cloud machines, Redis</li>
						<li>AWS SES, SQS, SSL, Cloudflare CDN</li>
						<li>Drupal, Drupal modules and theme development</li>
						<li>Bootstrap, Web Design, TWIG templates</li>
						<li>SEO, Digital Marketing, Google analytics, webmaster</li>
						<li>Linux, Shell scripting for various automations</li>
						<li>Image optimization techniques</li>
						<li>Node.js, MongoDB, Youtube APIs for channel sync</li>
						<li>Docker, Social Sharing services</li>
				</ul>
			</div>
		</div>
	</div>
	<div className="col-md-6">
		<div className="card border-dark mb-2">
			<div className="card-header">
				<h4>GyanBlog.com (Year 2019)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">A technical blog</h5>
				<p>It is a technical blog, which includes a variety of posts. Some of topics includes Coding best practices, programming languages features, Software architects, Micro services, Cloud architectures, Enterprise solutions, Maintainable architectures etc.</p>
				<h5>Features</h5>
				<ul>
					<li>Blogs, Articles</li>
					<li>Affiliates</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
						<li>AWS S3, Jekyll static website</li>
						<li>Bootstrap, Theme design</li>
						<li>SEO, Digital marketing, Sitemap, Webmaster, Google analytics</li>
						<li>Image optimization scripts</li>
						<li>Various automation scripts</li>
						<li>Cloudflare CDN, SSL</li>
						<li>Various website optimization techniques</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div className="row">
	<div className="col-md-6">
		<div className="card border-dark mb-2">
			<div className="card-header">
				<h4>LifeInText.com (Year 2019)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">A web portal for people who wants to record their life memories</h5>
				<h5>Features</h5>
				<ul>
					<li>Personal blog creation</li>
					<li>Users can mention their date of story, it can be used as a timeline in their profile.</li>
					<li>User can write stories as anonymous. Nobody can see who has written the story</li>
					<li>User can have totally private stories too, that only they can see.</li>
					<li>There are many plans for this, but this development is at rest for now.</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Php, Aws S3, Google cloud machines, Redis</li>
					<li>Cloudflare CDN, SSL</li>
					<li>Drupal, Drupal modules and theme development</li>
					<li>Bootstrap</li>
					<li>SEO, Digital Marketing, Google analytics, webmaster</li>
					<li>Docker</li>
				</ul>
			</div>
		</div>
	</div>
	<div className="col-md-6">
		<div className="card bg-light mb-2">
			<div className="card-header">
				<h4>KG se PG dot com (Year 2009)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">It WAS an educational site. Name says: KG to PG. Although, its name is sold to someone else now.</h5>
				<p>We designed well defined categories for students. We created slideshare.net kind of website. It became hit and alexa ranking reached to 3L, but we had to close it as we could not monetise it.</p>

				<h5>Features</h5>
				<ul>
					<li>Multi uploader of any type of files (pdf, ppt, doc etc)</li>
					<li>Conversion of different types of files to swf</li>
					<li>Multi-level conversion of files to image files. Single image per page of a file</li>
					<li>Used iNotify to detect file uploads, and run automation</li>
					<li>A player to run slideshow of images</li>
				</ul>
				<h5>Technologies and Skills</h5>
				<ul>
					<li>Drupal as CMS, PHP, Javascript</li>
					<li>SEO, Digital marketing, Sitemap, Webmaster, Google analytics</li>
					<li>Image optimization scripts</li>
					<li>Various automation scripts</li>
					<li>Various website optimization techniques</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div className="row">
	<div className="col">
		<div className="card border-dark mb-2">
			<div className="card-header">
				<h4>Misc (Over the years)</h4>
			</div>
			<div className="card-body">
				<h5 className="card-title">Misc Details over the years</h5>
				<ul>
					<li>Design and development of various frameworks</li>
					<li>Revamping existing projects for better optimization and maintainability</li>
					<li>Dashboards in Splunk and grafana</li>
					<li>Unit test cases for various part of code</li>
					<li>Integrtion with various security automation tools</li>
					<li>Team Leading for various projects</li>
					<li>Penetration testing</li>
					<li>Eclipse plugin development, Npm module development</li>
					<li>Presented a research POC/paper about Malware detection and security vulnerabilities in San fransisco Adobe Tech Summit</li>
					<li>Web Scraping iTunes for various analytic data and reviews.</li>
				</ul>
			</div>
		</div>
	</div>
</div>


</div>
</div>

      </SingleLayout>
    </>
  )
}

export default About